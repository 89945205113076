/* ///////////////////////////////// MAIN-NAV ///////////////////////////////////////////// */


.main-nav{
  display:none
}


.main-nav {
    border-radius: 25px;
    background-color: white;
    padding-top: 20px;
    padding-bottom: 20px;
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-area: 1/2/1/6;
    z-index: 1;
    transition: top 2s;
    display: none;
    -webkit-transform: translate3d(0, 0, 2px);
    transform: translate3d(0, 0, 2px);
    margin-left: 25px;
    margin-right: 25px;
  }
  

  .navActive {
    display: none;
  }
  
  .nav--btn {
    display: block;
    margin-top: auto;
    margin-bottom: auto;
    border: none;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    transition: 0.5s;
  }
  
  .nav--btn:hover {
    opacity: 0.8;
    transition: 0.5s;
    transform: scale(1.1);
    color: #1937b9;
    font-weight: bold;
  }
  .nav--btn a:hover {
    opacity: 0.8;
    transition: 0.5s;
    transform: scale(1.1);
    color: #1937b9;
    font-weight: bold;
  }
  
  .smartMenu {
  
    position: fixed;
    z-index: 1;
    gap: 15px;
    display: grid;
    bottom: 30px;
    right: 20px;
  }
  
  .sm {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(34,34,34, 0.1);
    cursor: pointer;
    margin-bottom: 0;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);

  
    opacity: 0.7;
  }
  
  @media screen and (max-width: 428px) {
  
  
  
  .sm{
    margin-bottom: 0;
  
  }
  
    .smartMenu {
      gap: 15px;
      right: 20px;
      bottom: 30px;
      display: grid;
    }
  }
  .sm:hover {
    transition: 0.5s;
    transform: scale(1.05);
  }
  
  .smartMenu--back {
    grid-row: 3/4;
  }
  
  .smartMenu--inactive {
    display: none;
    
  }
  
  .btnactive {
    color: #1937b9;
    font-weight: bold;
  }
  
  .btnactive a {
    color: #1937b9;
    font-weight: bold;
  }
  
  .subsection--cars {
    display: grid;
    grid-template-columns: auto;
    margin-bottom: gap 30px;
    max-height: 496px;
  }
  
  .btntxt {
    font-size: 15px;
    background: none;
  }
  
  .btndot {
    margin-top: 5px;
    background-color: #3e3e3e;
    opacity: 0.2;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    transition: all 0.5s;
    cursor: pointer;
  }
  
  .btndot:hover {
    opacity: 0.6;
  }